import { ApiConfigure } from '../../../ApiConfigure';
import { IApiCommand, IBaseCommandParam } from '../..';

interface IBasePermissionParam extends IBaseCommandParam {}

interface IGetPermissionsParam extends IBasePermissionParam {
    userId: string;
}

/**
 * Commands
 */

export class GetPermissionsCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)
    private PATH_NAME = '/admin/permissions';
    private params: string;

    constructor(param: IGetPermissionsParam) {
        // クエリパラメータをビルドする
        this.params = `?user_id=${param.userId}`; // &user_id=${param.userId}
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // config 構築
        const config: RequestInit = {
            method: 'GET',
            ...apiConfigure.fetchConfig,
        };
        // console.log(config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME + this.params;
        return await fetch(url, config);
    }
}
