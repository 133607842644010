import { ApiConfigure } from '../../ApiConfigure';
import { IApiCommand, IBaseCommandParam } from '..';

interface IBaseSummaryParam extends IBaseCommandParam {}

interface IGetSummaryParam extends IBaseSummaryParam {
    surveyId: string;
    showCounts?: boolean;
}

export class GetSummaryCommand implements IApiCommand<Response> {
    // API に投げる Request Body を構成するパラメータ群 (変数名は実際の API で求められるキー名にそろえる)
    private PATH_NAME = '/summary';
    private params: string;

    constructor(param: IGetSummaryParam) {
        // クエリパラメータをビルドする
        this.params = `?survey_id=${param.surveyId}&show_counts=${param.showCounts || false}`; // &user_id=${param.userId}
    }

    async execute(apiConfigure: ApiConfigure): Promise<Response> {
        // config 構築
        const config: RequestInit = {
            method: 'GET',
            ...apiConfigure.fetchConfig,
        };
        // console.log(config);

        const url = apiConfigure.apiBaseUrl + this.PATH_NAME + this.params;
        return await fetch(url, config);
    }
}
