import React, { useEffect, useState } from 'react';
import { convertOptionNumberKey } from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/admin/surveys/SaveSurveysRequestValidator';
import { SummaryEntity } from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/summary/Summary';
import Modal from 'react-modal';
import { SurveyEntity } from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/admin/surveys/SaveSurveysRequestValidator';
import { convertUtcToJst16 } from '../../utils/DateTimeManager';
import { Tooltip } from 'react-tooltip';
import { CopyIcon } from '@radix-ui/react-icons';
import { useJWebApi } from '../../features/api/useJWebApi';
import { ApiConfigure } from '../../features/api/ApiConfigure';
import { IApiCommand } from '../../features/api/commands';
import { ListSurveyCommand } from '../../features/api/commands/admin';
import { GetSummaryCommand, SaveVoteCommand } from '../../features/api/commands';
import { LoadingModal } from '../../components/ui-elements/UiElements';
import { useAuth } from '../../features/auth/AuthContext';
import UpdatePage from './UpdatePage';

interface ResultPageProps {
    isOpen: boolean;
    onClose: () => void;
    handleDeleteConfilmOpen: (id: string) => void;
    surveyId: string | undefined;
    admin: boolean;
    handleListUpdate: () => void;
    isOpenCancelModal: boolean;
    handleCancelModal: () => void;
    handleCancelModalClose: () => void;
    sitecode: string;
}
const ResultPage = ({
    isOpen,
    onClose,
    handleDeleteConfilmOpen,
    surveyId,
    admin,
    handleListUpdate,
    isOpenCancelModal,
    handleCancelModal,
    handleCancelModalClose,
    sitecode,
}: ResultPageProps) => {
    // API 設定
    const { idToken, userId } = useAuth();
    const apiKey = process.env.REACT_APP_JWEB_API_KEY;
    const apiConfigure = new ApiConfigure({
        apiKey: apiKey,
        authToken: idToken,
    });

    //Survey
    const { response, loading, call } = useJWebApi(apiConfigure);
    const { call: updateCall } = useJWebApi(apiConfigure);
    const [isItems, setIsItems] = useState<SurveyEntity | undefined>();
    const [update, setUpdate] = useState<boolean>(false);
    const handleSurveys = (call: (command: IApiCommand<Response>) => Promise<void>) => {
        if (!sitecode) {
            console.log('not sitecode', sitecode);
            return;
        }
        call(
            new ListSurveyCommand({
                sitecodes: [sitecode],
                userId: userId,
            }),
        );
        const surveys: SurveyEntity[] = response?.data.surveys;
        if (surveys) {
            const items = surveys.find((item) => item.id === surveyId);
            setIsItems(items);
        }
    };
    useEffect(() => {
        handleSurveys(call);
    }, [isOpen, update]);

    useEffect(() => {
        handleSurveys(updateCall);
    }, [response]);

    //Summury
    const { response: smryRes, call: callSmry, loading: smryLoading } = useJWebApi(apiConfigure);
    const summarys: SummaryEntity[] = response?.data.summarys;
    useEffect(() => {
        if (response) {
            callSmry(
                new GetSummaryCommand({
                    surveyId: surveyId as string,
                    showCounts: true,
                }),
            );
        }
    }, [isOpen, loading]);

    //Vote
    const handleVoteOpenClick = (optionKey: `option${number}`) => {
        call(
            new SaveVoteCommand({
                body: {
                    surveyId: surveyId as string,
                    selectedOptions: [optionKey],
                },
            }),
        );
    };

    //時間
    const [nowTime, setNowTime] = useState('');
    useEffect(() => {
        const getCurrentTime = () => {
            const now = new Date();
            const japanOffset = 9 * 60;
            const japanTime = new Date(now.getTime() + japanOffset * 60 * 1000);
            const iso8601Time = japanTime.toISOString();
            setNowTime(iso8601Time);
        };
        getCurrentTime();
        const intervalId = setInterval(() => {
            getCurrentTime();
        }, 1000);

        return () => clearInterval(intervalId);
    }, []);

    const hiddenCheck: boolean = admin
        ? true
        : (isItems && convertUtcToJst16(isItems.startDateTime) > nowTime) || false;

    //クリップボード
    const [copy, setCopy] = useState('投票IDをコピー');
    const handleCopyClick = () => {
        setCopy('クリップボードにコピーしました');
    };

    //✕ボタン
    const handleResultClose = () => {
        onClose();
        handleUpdateFalse();
        handleListUpdate();
    };
    const handleCancelCheck = () => {
        setCopy('投票IDをコピー');
        if (!update) {
            handleResultClose();
        } else {
            handleCancelModal();
        }
    };

    //更新
    const handleUpdateTrue = () => {
        setUpdate(true);
    };
    const handleUpdateFalse = () => {
        setUpdate(false);
    };

    const modalTitle = 'text-xl font-bold indent-2  border-b-2';
    const modalBody = 'pl-3 pt-2';
    const customStyles = {
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0.5)',
        },
        content: {
            width: '35%',
            height: '75%',
            margin: 'auto',
            backgroundColor: 'white',
            borderRadius: '10px',
        },
    };

    return (
        <>
            <Modal
                isOpen={isOpen}
                onRequestClose={handleCancelCheck}
                contentLabel="Item Details"
                style={customStyles}
                ariaHideApp={false}
            >
                <LoadingModal open={loading} admin={admin} />
                {isItems && (
                    <div className=" flex h-full  w-full justify-center p-6 ">
                        <div className=" h-full w-full ">
                            {/* ✕ボタン */}
                            <div className="absolute right-6 top-4 hover:text-yellow-400">
                                <button onClick={handleCancelCheck}>✕</button>
                            </div>
                            {/* 状態*/}
                            <div className=" w-fit  ">
                                <p
                                    className={`rounded-full px-5 py-1 text-xl  font-bold shadow ${
                                        nowTime < convertUtcToJst16(isItems.startDateTime)
                                            ? 'bg-basecolor text-white'
                                            : convertUtcToJst16(isItems.endDateTime) < nowTime
                                              ? 'bg-gray-400 text-white'
                                              : 'bg-red-400 text-white'
                                    }`}
                                >
                                    {nowTime < convertUtcToJst16(isItems.startDateTime)
                                        ? '期間前'
                                        : convertUtcToJst16(isItems.endDateTime) < nowTime
                                          ? '期間終了'
                                          : '期間中'}
                                </p>
                            </div>

                            {/* ID */}
                            <div className="mt-4 w-full">
                                <div className="flex w-full border-b-2">
                                    <p className="indent-2 text-xl font-bold  ">投票ID</p>
                                    <div className="ml-auto flex">
                                        {/* 編集 */}
                                        <button
                                            className={`mb-1 mr-2 rounded-full border-2 px-8 shadow hover:border-yellow-400  ${update ? ' border-yellow-400 ' : ''}`}
                                            onClick={handleUpdateTrue}
                                        >
                                            編集
                                        </button>

                                        {/* 削除 */}
                                        <div className=" pl-2 ">
                                            <button
                                                className=" mb-1 rounded-full border-2 px-8 shadow hover:border-yellow-400"
                                                onClick={() => {
                                                    if (isItems.id) {
                                                        handleDeleteConfilmOpen(isItems.id);
                                                    }
                                                }}
                                            >
                                                削除
                                            </button>
                                        </div>
                                    </div>
                                </div>

                                <div className="flex w-full">
                                    <p className="flex-1 pl-3 pt-2">{isItems.id}</p>

                                    <button
                                        onClick={() => {
                                            navigator.clipboard.writeText(
                                                isItems.id || 'Default Text',
                                            );
                                            handleCopyClick();
                                        }}
                                        data-tooltip-id="copy"
                                        className=" ml-auto "
                                    >
                                        <div className=" text-gray-400">
                                            <CopyIcon />
                                        </div>
                                    </button>
                                    <Tooltip id="copy" place="top-start" content={copy} />
                                </div>
                            </div>
                            {update ? (
                                <UpdatePage
                                    isItems={isItems}
                                    summarys={summarys}
                                    smryLoading={smryLoading}
                                    smryRes={smryRes}
                                    handleVoteOpenClick={handleVoteOpenClick}
                                    nowTime={nowTime}
                                    hiddenCheck={hiddenCheck}
                                    handleUpdateFalse={handleUpdateFalse}
                                    admin={admin}
                                    isOpenCancelModal={isOpenCancelModal}
                                    handleCancelModal={handleCancelModal}
                                    handleCancelModalClose={handleCancelModalClose}
                                    handlecloseClick={handleCancelCheck}
                                    handleResultClose={handleResultClose}
                                    sitecode={sitecode}
                                />
                            ) : (
                                <>
                                    {/* 説明 */}
                                    <div className="mt-4 w-full">
                                        <p className={modalTitle}>説明</p>
                                        <p className={modalBody}>{isItems.description}</p>
                                    </div>
                                    {/* 質問 */}
                                    <div className="mt-4 w-full">
                                        <p className={modalTitle}>質問</p>
                                        <p className={modalBody}>{isItems.questionBody}</p>
                                    </div>
                                    {/* 回答期間 */}
                                    <div className="mt-4 w-full">
                                        <p className={modalTitle}>回答期間</p>
                                        <div className={modalBody}>
                                            <label className=" font-bold ">開始日時</label>
                                            <span className=" pl-3">
                                                {convertUtcToJst16(isItems.startDateTime)
                                                    .replace('T', ' ')
                                                    .replace(/-/g, '/')}
                                            </span>
                                        </div>

                                        <div className="pl-3 pt-1">
                                            <label className=" font-bold ">終了日時</label>
                                            <span className=" pl-3">
                                                {convertUtcToJst16(isItems.endDateTime)
                                                    .replace('T', ' ')
                                                    .replace(/-/g, '/')}
                                            </span>
                                        </div>
                                    </div>
                                    {/* 選択肢リスト */}
                                    <div className="mt-4 w-full pb-12">
                                        <div className="flex">
                                            <p className={`${modalTitle} flex-1`}>回答結果</p>
                                            <p className="ml-auto border-b-2 pr-4 font-bold">
                                                {`${isItems.allowMultiSelect ? '複数' : '単一'}  `}
                                            </p>
                                        </div>
                                        <div
                                            className={`mx-4 mt-4 rounded-md px-3 pb-4 pt-3 shadow`}
                                        >
                                            {Object.entries(isItems.options)
                                                .sort((a, b) =>
                                                    a[0].localeCompare(b[0], undefined, {
                                                        numeric: true,
                                                    }),
                                                )
                                                .map(([key, value], index) => {
                                                    const optionKey = convertOptionNumberKey(key);
                                                    const foundIndex = summarys
                                                        ? summarys.findIndex(
                                                              (summary) =>
                                                                  summary.surveyId === isItems.id,
                                                          )
                                                        : {};
                                                    return (
                                                        <tr
                                                            key={isItems.id + key}
                                                            className=" w-full  leading-loose"
                                                        >
                                                            <td className=" w-full whitespace-nowrap border-b-2 border-dashed">
                                                                <span>選択肢</span>
                                                                <span className="pl-1">
                                                                    {index + 1}
                                                                </span>
                                                            </td>
                                                            <td className="whitespace-nowrap border-b-2 border-dashed ">{`${value}`}</td>
                                                            <td
                                                                className={`whitespace-nowrap border-b-2 border-dashed `}
                                                            >
                                                                <p
                                                                    className={`mx-2 w-16 border-2 pr-1 text-end leading-tight`}
                                                                >
                                                                    {smryLoading
                                                                        ? '　'
                                                                        : foundIndex !== -1
                                                                          ? smryRes?.data[key] || 0
                                                                          : '-'}
                                                                </p>
                                                            </td>
                                                            {admin && (
                                                                <>
                                                                    <td className=" w-full border-b-2  border-dashed">
                                                                        <div className="flex items-center justify-center">
                                                                            <button
                                                                                onClick={() => {
                                                                                    handleVoteOpenClick(
                                                                                        optionKey,
                                                                                    );
                                                                                }}
                                                                                className=" w-fit rounded-full  border-2 bg-white  shadow hover:border-yellow-400"
                                                                                data-tooltip-id="voteBtn"
                                                                                disabled={
                                                                                    nowTime <
                                                                                    convertUtcToJst16(
                                                                                        isItems.startDateTime,
                                                                                    )
                                                                                        ? true
                                                                                        : convertUtcToJst16(
                                                                                                isItems.endDateTime,
                                                                                            ) <
                                                                                            nowTime
                                                                                          ? true
                                                                                          : false
                                                                                }
                                                                            >
                                                                                <p className="whitespace-nowrap  px-2 leading-tight ">
                                                                                    一票入れる
                                                                                </p>
                                                                            </button>
                                                                        </div>

                                                                        <Tooltip
                                                                            id="voteBtn"
                                                                            place="top"
                                                                            content={
                                                                                nowTime <
                                                                                convertUtcToJst16(
                                                                                    isItems.startDateTime,
                                                                                )
                                                                                    ? '投票期間前です。'
                                                                                    : convertUtcToJst16(
                                                                                            isItems.endDateTime,
                                                                                        ) < nowTime
                                                                                      ? '投票期間が終了しています。'
                                                                                      : ''
                                                                            }
                                                                        />
                                                                    </td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    );
                                                })}
                                        </div>
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                )}
            </Modal>
        </>
    );
};

export default ResultPage;
