import { TIME_OUT_MS } from '../../constants';

import { IReject } from '../../cdk/JWebSurveyApp/lib/lambda_functions/commons/ResponseBuilder';

export const convertErrorToReject = (
    error: unknown,
    detail?: { isTimeout?: boolean; obj?: any },
): IReject => {
    let rejected: IReject;

    // console.error("error", error, "detail", detail);
    if (error instanceof Response) {
        // エラーとしてレスポンスが返された\
        let statusText;
        let errorMessage;
        if (detail?.obj) {
            try {
                // JSON 形式であれば message を抽出
                const parsed = JSON.parse(detail.obj);
                // console.log("parsed", parsed);
                errorMessage = parsed.errorMessage;
                statusText = parsed.statusText;
            } catch (error) {
                // TODO: [確認] JSON 形式で無ければそのまま表示
                // API に届いていた場合は以下な感じ (以下は日本語を脚本として入れると返ってくるエラー)
                // {"code":500,"message":"TypeError: Unicode-objects must be encoded before hashing\\n","content":[]}
                console.error(error);
                statusText = 'Bad Gateway';
                errorMessage = detail.obj;
                // // HTML の解析 (AWS ALB のレスポンス)
                // /**
                //  * 想定 Html (ALB のデフォルト BadGateway ページ)
                //  * <html>
                //  *   <head>
                //  *     <title>502 Bad Gateway</title>
                //  *   </head>
                //  *   <body>
                //  *     <center>
                //  *       <h1>502 Bad Gateway</h1>
                //  *     </center>
                //  *   </body>
                //  * </html>
                //  */
                // const doc = new DOMParser().parseFromString(obj, 'text/html');
                // const title = doc.getElementsByTagName('title'); // 注意 : 取得しているのはブラウザタブに表示される タイトル (h1 要素ではない)
                // const titleInnerText = title[0].innerText;
                // const status = parseInt(titleInnerText.substring(0, 3));

                // if (status === 502) {
                //   message = 'Healthy ALB target does not exist. Engine is not started.';
                // } else {
                //   message = 'Unexpected Error.';
                // }
                // rejected = {
                //   status: status,
                //   statusText: titleInnerText.substring(4),
                //   errorMessage: msg,
                // };
            }
        } else {
            statusText = 'InternalServerError';
            errorMessage = 'Please check server side logs.';
        }
        rejected = {
            status: error.status,
            statusText: statusText,
            errorMessage: errorMessage,
        };
    } else if (error instanceof DOMException) {
        if (detail?.isTimeout) {
            // クライアント側からタイムアウトした
            // 408
            // https://developer.mozilla.org/ja/docs/Web/HTTP/Status/408
            // https://docs.aws.amazon.com/en_en/elasticloadbalancing/latest/classic/ts-elb-error-message.html#ts-elb-errorcodes-http408
            rejected = {
                status: 408,
                statusText: 'Request Timeout',
                errorMessage: `Request time out by client. (${TIME_OUT_MS} ms proceeded)`,
            };
        } else {
            // クライアント側からキャンセルした
            //　https://www.webfx.com/web-development/glossary/http-status-codes/what-is-a-499-status-code/
            rejected = {
                status: 499,
                statusText: 'Request Canceled',
                errorMessage: `Client closed request.`,
            };
        }
        // else if そのほかコードがあるエラーを else if で列挙
    } else {
        // fetch 関数でのエラー
        // サーバーとの接続を確立する前に発生したエラー
        // https://www.web-dev-qa-db-fra.com/fr/ajax/quest-ce-que-cela-signifie-lorsquune-requete-http-renvoie-le-code-detat-0/957510238/
        if ((error as Error).message === 'Failed to fetch') {
            rejected = {
                status: 0,
                statusText: (error as Error).name,
                errorMessage: `${(error as Error).message}. Reason is CORS, Protocol, port and so on.`,
            };
        } else {
            rejected = {
                status: 0,
                statusText: (error as Error).name,
                errorMessage: (error as Error).message,
            };
        }
    }

    console.log('rejected', rejected);
    return rejected;
};
