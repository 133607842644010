import React from 'react';
import { NavLink } from 'react-router-dom';
import { FilePlusIcon, ListBulletIcon } from '@radix-ui/react-icons';

interface SideBarProps {
    admin: boolean;
    sitecode: string;
}

interface isActive {
    isActive: boolean;
}

const SideBar = ({ admin, sitecode }: SideBarProps) => {
    const navStyle = ({ isActive }: isActive) => {
        return isActive
            ? ` bg-secondcolor py-2  pl-4 font-bold ${admin ? 'text-adminbasecolor' : 'text-basecolor'}`
            : `  py-2 pl-4 font-bold text-secondcolor hover:bg-secondcolor  ${admin ? 'bg-adminbasecolor hover:text-adminbasecolor' : 'bg-basecolor hover:text-basecolor'}`;
    };
    return (
        <div
            className={`fixed left-0 z-50 h-screen w-52 
           text-white ${admin ? 'bg-adminbasecolor' : 'bg-basecolor'}`}
        >
            <img src="/survey2.png" alt="ロゴ" className=" mx-auto mt-4 w-1/2" />
            <p className="text-center text-3xl font-bold">PICK ONE</p>

            <div className="mt-10 flex w-full flex-col  text-xl ">
                <NavLink
                    to={`${admin ? '/admin/surveys' : '/surveys'}`}
                    state={sitecode}
                    className={navStyle}
                >
                    <div className="flex items-center ">
                        <div className="mr-2 scale-125">
                            <FilePlusIcon />
                        </div>
                        <p>投票の作成</p>
                    </div>
                </NavLink>
                <NavLink
                    to={`${admin ? '/admin/survey_list' : '/survey_list'}`}
                    state={sitecode}
                    className={navStyle}
                >
                    <div className="flex items-center  ">
                        <div className="mr-2  scale-125 ">
                            <ListBulletIcon />
                        </div>
                        <p> 投票一覧</p>
                    </div>
                </NavLink>
                {/* <NavLink to={`/signout`} className={navStyle}>
                    <div className="flex items-center  ">
                        <p>サインアウト</p>
                    </div>
                </NavLink> */}
            </div>
        </div>
    );
};

export default SideBar;
