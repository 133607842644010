import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { PlusCircledIcon, TrashIcon } from '@radix-ui/react-icons';
import { LoadingModal, ConfirmModal, ResultModal } from '../../components/ui-elements/UiElements';
import { useJWebApi } from '../../features/api/useJWebApi';
import { SaveSurveyCommand } from '../../features/api/commands';
import {
    TWO_HOURS,
    TWO_WEEKS,
    calculateFutureTimeISO,
    convertJst16ToUtc,
} from '../../utils/DateTimeManager';
import { ApiConfigure } from '../../features/api/ApiConfigure';
import { useAuth } from '../../features/auth/AuthContext';
import { OptionsRecord } from '../../cdk/JWebSurveyApp/lib/lambda_functions/apis/admin/surveys/SaveSurveysRequestValidator';
import {
    validateDateTime,
    validateText,
} from '../../cdk/JWebSurveyApp/lib/lambda_functions/commons/CommonValidatator';
import SideBar from '../../components/layouts/SideBar';
import SignIn from '../signin/SignIn';
import AdminSignIn from '../signin/AdminSignIn';
import { Tooltip } from 'react-tooltip';
import { IReject } from '../../cdk/JWebSurveyApp/lib/lambda_functions/commons/ResponseBuilder';
import DropDown from '../../components/ui-elements/DropDown';

const MIN_OPTION_NUM = 2;
const MAX_OPTION_NUM = 10;

const Now = new Date();

type VoteFormData = {
    questionBody: string;
    description: string;
    options: string[];
    allowMultiSelect: boolean;
    startDateTime: string;
    endDateTime: string;
};
interface SurveyFormPageProps {
    admin: boolean;
}

const SurveyFormPage = ({ admin }: SurveyFormPageProps) => {
    document.body.style.overflow = 'hidden';
    const navigate = useNavigate(); // リダイレクトのためのhook
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue,
        getValues,
        trigger,
        watch,
    } = useForm<VoteFormData>();

    // UI States
    const [currentOptionLength, setCurrentOptionLength] = useState<number>(MIN_OPTION_NUM);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState<boolean>(false);

    //Api Handler
    const { idToken, userId } = useAuth();
    const apiConfigure = new ApiConfigure({
        authToken: idToken,
    });
    const { response, loading, error, call } = useJWebApi(apiConfigure);

    useEffect(() => {
        if (error) {
            const err = error as IReject;
            alert(`[${err.status}] ${err.statusText}: ${err.errorMessage}`);
        }
    }, [error]);

    //サイトコード
    const [selectedSitecode, setSelectedSiteCode] = useState<string>('');

    //フォーム
    const formSubmit = () => {
        const formData = getValues();
        // options を構成
        let options: OptionsRecord = {};
        formData.options.forEach((option, index) => {
            options = { ...options, [`option${index + 1}`]: option };
        });

        const allowMultiSelect =
            process.env.REACT_APP_TARGET_ENV === 'Production' ? false : formData.allowMultiSelect;

        call(
            new SaveSurveyCommand({
                userId: userId,
                body: {
                    ...formData,
                    options: options,
                    startDateTime: convertJst16ToUtc(formData.startDateTime),
                    endDateTime: convertJst16ToUtc(formData.endDateTime),
                    allowMultiSelect: allowMultiSelect,
                    id: response?.data.id,
                    createdAt: response?.data.createdAt,
                    updatedAt: response?.data.updatedAt,
                    sitecodes: [selectedSitecode],
                },
            }),
        );
    };
    const handleModalOnlyClose = () => {
        setIsOpenConfirmModal(false);
    };

    // Utils
    const handleOpenModal = async () => {
        const isValid = await trigger();
        if (isValid) {
            setIsOpenConfirmModal(true);
        }
    };

    const validateSurveyDateTime = () => {
        const startDateTime = watch('startDateTime');
        const endDateTime = watch('endDateTime');
        if (endDateTime === '' || startDateTime === '') return true;

        return validateDateTime(startDateTime, endDateTime);
    };

    const textareaStyle = `mt-4 h-20 w-11/12 rounded-lg border-2 valid:pl-2 valid:pt-1 focus:bg-yellow-50 focus:outline-none ${admin ? 'focus:border-adminbasecolor' : 'focus:border-basecolor'}`;
    const dateTimeStyle = `w-64 rounded-lg border-2 px-1 py-2 focus:bg-yellow-50 focus:outline-none ${admin ? 'focus:border-adminbasecolor' : 'focus:border-basecolor'}`;
    const errorStyle = 'text-sm text-red-500 ml-1';

    const { isSignedIn } = useAuth();
    if (!isSignedIn) {
        if (admin) {
            return <AdminSignIn />;
        } else {
            return <SignIn />;
        }
    }
    return (
        <div className=" overflow-x-hidden ">
            <div className="flex ">
                <SideBar admin={admin} sitecode={selectedSitecode} />
                <div className={`ml-52 w-full`}>
                    <>
                        <ConfirmModal
                            open={isOpenConfirmModal}
                            admin={admin}
                            handleClose={() => {
                                setIsOpenConfirmModal(false);
                            }}
                            handleConfirm={formSubmit}
                            handleModalOnlyClose={handleModalOnlyClose}
                        />
                        <ResultModal
                            open={response?.data.id}
                            admin={admin}
                            text={response?.data.id}
                            handleClose={() => {
                                navigate(`${admin ? '/admin/survey_list' : '/survey_list'}`, {
                                    state: selectedSitecode,
                                });
                            }}
                        />
                        <LoadingModal open={loading} admin={admin} />
                    </>

                    <div className="  h-full  min-h-screen p-10 ">
                        <div className="flex w-full items-center">
                            <label className="ml-6 whitespace-nowrap text-2xl font-bold">
                                投票の作成
                            </label>
                            <div className="ml-auto pr-5 text-end">
                                <DropDown
                                    admin={admin}
                                    selectedSitecode={selectedSitecode}
                                    setSelectedSiteCode={setSelectedSiteCode}
                                />
                            </div>
                        </div>
                        <div className=" m-4  mt-4 h-85% overflow-y-auto rounded-xl bg-white shadow-lg ">
                            <form onSubmit={handleSubmit(handleOpenModal)} className="p-6">
                                <div className=" mt-3 flex flex-col">
                                    <label
                                        htmlFor="questionBody"
                                        className="border-b-2 px-2 text-xl"
                                    >
                                        質問
                                    </label>
                                    <textarea
                                        id="questionBody"
                                        placeholder={`質問を入力してください`}
                                        {...register('questionBody', {
                                            validate: validateText,
                                        })}
                                        className={textareaStyle}
                                    />
                                    {errors.questionBody && (
                                        <p className={errorStyle}>{errors.questionBody?.message}</p>
                                    )}
                                </div>

                                <div className="mt-2 flex flex-col">
                                    <label
                                        htmlFor="description"
                                        className="border-b-2 px-2 text-xl"
                                    >
                                        説明
                                    </label>
                                    <textarea
                                        id="description"
                                        placeholder={`説明を入力してください`}
                                        {...register('description', {
                                            validate: validateText,
                                        })}
                                        className={textareaStyle}
                                    />
                                    {errors.description && (
                                        <p className={errorStyle}>{errors.description?.message}</p>
                                    )}
                                </div>

                                <div className="flex flex-col">
                                    <label
                                        htmlFor="options"
                                        className=" mt-4 border-b-2  pl-2 text-xl"
                                    >
                                        選択肢
                                    </label>
                                    <div data-tooltip-id="options" className="w-fit ">
                                        {Array.from({ length: currentOptionLength }).map(
                                            (_, idx) => (
                                                <>
                                                    <div className="flex items-center">
                                                        <p key={idx} className="mt-2 ">
                                                            <input
                                                                id="options"
                                                                type="text"
                                                                placeholder={`回答 ${idx + 1}`}
                                                                {...register(`options.${idx}`, {
                                                                    required: '設問は必須です。',
                                                                    validate: validateText,
                                                                })}
                                                                className={`mt-2 w-80 rounded-lg border-2 py-2 pl-2 focus:bg-yellow-50 focus:outline-none ${admin ? 'focus:border-adminbasecolor' : 'focus:border-basecolor'}`}
                                                            />
                                                        </p>

                                                        {/* 削除ボタン */}
                                                        <div>
                                                            {idx > 1 &&
                                                                (idx === currentOptionLength - 1 ||
                                                                    currentOptionLength >
                                                                        MIN_OPTION_NUM) && (
                                                                    <button
                                                                        type="button"
                                                                        color="error"
                                                                        tabIndex={-1}
                                                                        onClick={() => {
                                                                            const option =
                                                                                getValues().options;
                                                                            setCurrentOptionLength(
                                                                                option.length - 1,
                                                                            );
                                                                            setValue('options', [
                                                                                ...option.filter(
                                                                                    (_, i) =>
                                                                                        i !== idx,
                                                                                ),
                                                                            ]);
                                                                        }}
                                                                        className={`scale-150 pl-2 pt-3 ${admin ? 'text-adminbasecolor' : 'text-basecolor'}`}
                                                                    >
                                                                        <TrashIcon />
                                                                    </button>
                                                                )}
                                                        </div>
                                                    </div>
                                                    <div>
                                                        {errors.options?.[idx] && (
                                                            <p className={errorStyle}>
                                                                {errors.options?.[idx]?.message}
                                                            </p>
                                                        )}
                                                    </div>
                                                </>
                                            ),
                                        )}
                                    </div>
                                </div>

                                {/* 追加ボタン */}
                                <div>
                                    {currentOptionLength < MAX_OPTION_NUM && (
                                        <button
                                            type="button"
                                            tabIndex={-1}
                                            onClick={() => {
                                                setCurrentOptionLength(
                                                    getValues().options.length + 1,
                                                );
                                            }}
                                            className={`scale-150 pl-2 pt-3 focus:outline-none ${admin ? 'text-adminbasecolor' : 'text-basecolor'}`}
                                        >
                                            <PlusCircledIcon />
                                        </button>
                                    )}
                                </div>
                                {/* 複数選択可 */}
                                <div className="hidden">
                                    {process.env.REACT_APP_TARGET_ENV !== 'Production' && (
                                        <div>
                                            <label className="flex">
                                                <div className="ml-2 mt-2  ">複数選択可</div>

                                                <input
                                                    className="ml-1 mt-2 "
                                                    type="checkbox"
                                                    {...register('allowMultiSelect')}
                                                ></input>
                                            </label>
                                        </div>
                                    )}
                                </div>
                                {/* 回答期限 */}
                                <div className="flex flex-col">
                                    <label
                                        htmlFor="start"
                                        className=" mt-4 border-b-2  pl-2 text-xl"
                                    >
                                        投票期間
                                    </label>
                                    <div className=" mt-2 ">
                                        <div data-tooltip-id="DateTime" className="w-fit">
                                            <div className="mt-4">
                                                <div className="flex">
                                                    <div>
                                                        <label
                                                            htmlFor="start"
                                                            className="relative flex flex-col"
                                                        >
                                                            <p className="absolute -top-2 ml-3 bg-white px-2 text-xs">
                                                                開始日時
                                                            </p>
                                                            <input
                                                                id="start"
                                                                type="datetime-local"
                                                                className={dateTimeStyle}
                                                                {...register('startDateTime', {
                                                                    required:
                                                                        '開始日時は必須です。',
                                                                    validate:
                                                                        validateSurveyDateTime,
                                                                })}
                                                                // 過去は指定できない。
                                                                min={calculateFutureTimeISO(Now)}
                                                                // 未来は限定しない。
                                                            />
                                                        </label>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setValue('startDateTime', '');
                                                        }}
                                                        className="my-1 ml-2 rounded-full border-2 px-4 hover:border-yellow-400 "
                                                    >
                                                        リセット
                                                    </button>
                                                </div>

                                                {errors.startDateTime && (
                                                    <p className={errorStyle}>
                                                        {errors.startDateTime?.message}
                                                    </p>
                                                )}
                                            </div>

                                            <div className="mt-4">
                                                <div className="flex">
                                                    <div>
                                                        <label className="relative flex flex-col">
                                                            <label
                                                                htmlFor="end"
                                                                className="absolute -top-2 ml-3 bg-white px-2 text-xs"
                                                            >
                                                                終了日時
                                                            </label>

                                                            <input
                                                                id="end"
                                                                type="datetime-local"
                                                                className={dateTimeStyle}
                                                                {...register('endDateTime', {
                                                                    required:
                                                                        '終了日時は必須です。',
                                                                    validate:
                                                                        validateSurveyDateTime,
                                                                })}
                                                                min={calculateFutureTimeISO(
                                                                    new Date(
                                                                        watch('startDateTime') ||
                                                                            Now,
                                                                    ),
                                                                    TWO_HOURS,
                                                                )}
                                                                max={calculateFutureTimeISO(
                                                                    new Date(
                                                                        watch('startDateTime') ||
                                                                            Now,
                                                                    ),
                                                                    TWO_WEEKS,
                                                                )}
                                                            />
                                                        </label>
                                                    </div>
                                                    <button
                                                        type="button"
                                                        onClick={() => {
                                                            setValue('endDateTime', '');
                                                        }}
                                                        className="my-1 ml-2 rounded-full border-2 px-4 hover:border-yellow-400"
                                                    >
                                                        リセット
                                                    </button>
                                                </div>
                                                {errors.endDateTime && (
                                                    <p className={errorStyle}>
                                                        {errors.endDateTime?.message}
                                                    </p>
                                                )}
                                            </div>
                                        </div>
                                        <Tooltip
                                            id="DateTime"
                                            place="bottom-start"
                                            content="開始/終了を2時間空けてください。2週間後まで設定できます。"
                                        />
                                    </div>
                                </div>
                                {/* 作成ボタン */}
                                <div className="  ml-1 mt-4 flex w-full  items-center justify-center px-3 py-1">
                                    <button
                                        type="submit"
                                        className={`ml-6 w-52 rounded-full border-2 p-2 text-white hover:bg-white ${admin ? 'border-adminbasecolor bg-adminbasecolor hover:text-adminbasecolor' : 'border-basecolor bg-basecolor hover:text-basecolor'}`}
                                    >
                                        作成
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default SurveyFormPage;
